import axios from "axios";

export class LpApiService {
  static domain = process.env.NODE_ENV === "production" ? "https://upload.liveportrait.com" : "http://0.0.0.0:8887";

  static async uploadVideo(file: File, code: string, email: string) {
    const bodyFormData = new FormData();
    bodyFormData.append('image', file);
    const fileName = file.name.replace(/(\.[^.]+$)/, '_' + (new Date()).getTime() / 1000 + '$1')

    const headers = {
      "Content-Type": file.type,
      "X-Email": email,
      "X-Code": code,
      "X-File-Name": encodeURIComponent(fileName)
    }

    return await axios.post(`${(LpApiService.domain)}/myvideo/add_video`, file, {headers})
  }

//
//   xhr.open(method, endpointWithDomain)
//
//   xhr.responseType = 'json'
//   xhr.setRequestHeader("Api-Key", lpApi.apiKey);
//   xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
//
//   headers["Content-Type"] = 'application/json' unless headers["Content-Type"]
//   xhr.setRequestHeader(header, value) for header, value of headers;
//
//   xhr.upload.addEventListener("progress", options['onprogress'], false) if options['onprogress']
//   xhr.addEventListener("load", options['onload'], false) if options['onload']
//
//   xhr.send(payload);
//
//   interactiveImages: {
//     get: (interactiveImageId, options = {}) ->
//     lpApi.request('GET', '/api/interactive_images/' + interactiveImageId, '', {}, options)
//   post: (imageId, videoId, params = {}, options = {}) ->
//   postData = {image_id: imageId, video_id: videoId}
//   postData = $.extend(postData, params)
//   lpApi.request('POST', '/api/interactive_images', JSON.stringify(postData), {}, options)
// },
//
// images: {
//   get: (id, options = {}) ->
//     lpApi.request('GET', '/api/images/' + id, '', {}, options)
//   post: (file, options = {}) ->
//     fileName = file.name.replace(/(\.[^.]+$)/, '_' + parseInt((new Date()).getTime() / 1000) + '$1')
//
//   headers = {
//     "Content-Type": file.type
//     'X-File-Name': encodeURIComponent(fileName)
//   }
//
//   lpApi.request('POST', '/api/images', file, headers, options)
// }
//
// videos: {
//   get: (id, options = {}) ->
//     lpApi.request('GET', '/api/videos/' + id, '', {}, options)
//   post: (file, options = {}) ->
//     fileName = file.name.replace(/(\.[^.]+$)/, '_' + parseInt((new Date()).getTime() / 1000) + '$1')
//
//   headers = {
//     "Content-Type": file.type
//     'X-File-Name': encodeURIComponent(fileName)
//   }
//
//   lpApi.request('POST', '/api/videos', file, headers, options)
// }
}